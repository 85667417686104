import React, { useContext, useEffect, useState } from "react";
// import { withRouter } from "react-router";
import { AuthContext } from "../contexts/Auth";
import UploadForm from "../comps/UploadForm";
import ImageGrid from "../comps/ImageGrid";
import Modal from "../comps/Modal";
import Create from "../comps/create";
import AdminPortfolio from "../comps/AdminPortfolio";
// import PortfolioGet from "../comps/portfolioGet";
// import { projectFirestore } from "../firebase/config";

const Admin = () => {
  const [selectedImg, setSelectedImg] = useState("");
  const [subSelectedImg, setSubSelectedImg] = useState("");
  const { signout } = useContext(AuthContext);
  const [copyImg, setCopyImg] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [edit, setEdit] = useState("");
  const [createStatus, setCreateStatus] = useState("記事作成");

  //インデックス
   const [isClick, setClick] = useState(0);
   const [createIndex, setCreateIndex] = useState(1);
   const [portfolioIndex, setPortfolioIndex] = useState(3);
   const [imageIndex, setImageIndex] = useState(2);
   const [indexUp, setUp] = useState(0);
   const addIndex = (e) => {
     if (e.target.classList.contains("portfolio_type_tag1")) {
     } else {
       setClick(isClick + 1);
       e.target.classList.add("click");
       e.target.parentNode.classList.add("move");
     }
   };

   const addEditIndex = () => {
     setClick(isClick + 1);
     document.getElementById("create_tag").classList.add("click");
     document.getElementById("create_tag").parentNode.classList.add("move");
   }
  //インデックス

  const [isImageActive, setImageActive] = useState("false");
  const imageGridToggle = () => {
    setImageActive(!isImageActive);
  };

  const imageModal =(url)=> {
    setSelectedImg(url)
    console.log("osareta")
  }

  return (
    <div className="App">
      <UploadForm
        isClick={isClick}
        setClick={setClick}
        index={imageIndex}
        setIndex={setImageIndex}
        addIndex={addIndex}
        indexUp={indexUp}
        setUp={setUp}
        imageGridToggle={imageGridToggle}
        setImageActive={setImageActive}
        isImageActive={isImageActive}
        setSelectedImg={setSubSelectedImg}
        selectedImg={subSelectedImg}
        copyImg={copyImg}
        setCopyImg={setCopyImg}
        imageModal={imageModal}
      />
      <AdminPortfolio
        deleteId={deleteId}
        setDeleteId={setDeleteId}
        setEdit={setEdit}
        edit={edit}
        isClick={isClick}
        setClick={setClick}
        index={portfolioIndex}
        setIndex={setPortfolioIndex}
        addIndex={addIndex}
        indexUp={indexUp}
        setUp={setUp}
        addEditIndex={addEditIndex}
      />
      <div
        className={`subImageGrid ${isImageActive ? "" : "subImageGrid_move"}`}
      >
        <ImageGrid
          imageGridToggle={imageGridToggle}
          setImageActive={setImageActive}
          isImageActive={isImageActive}
          setSelectedImg={setSelectedImg}
          copyImg={copyImg}
          setCopyImg={setCopyImg}
          modalStatus={"quiq"}
        />
        {/* {selectedImg && (
          <Modal
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
            copyImg={copyImg}
            setCopyImg={setCopyImg}
          />
        )} */}
      </div>
      <Create
        imageGridToggle={imageGridToggle}
        setImageActive={setImageActive}
        isImageActive={isImageActive}
        selectedImg={selectedImg}
        copyImg={copyImg}
        setCopyImg={setCopyImg}
        createStatus={createStatus}
        setCreateStatus={setCreateStatus}
        edit={edit}
        isClick={isClick}
        setClick={setClick}
        index={createIndex}
        setIndex={setCreateIndex}
        addIndex={addIndex}
        indexUp={indexUp}
        setUp={setUp}
      />
      {selectedImg && (
        <Modal
          selectedImg={selectedImg}
          setSelectedImg={setSelectedImg}
          copyImg={copyImg}
          setCopyImg={setCopyImg}
        />
      )}
      <p className="signout" onClick={signout}>
        サインアウト
        <br />
        する？
      </p>
    </div>
  );
};
export default Admin;
