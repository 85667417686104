import useFirestore from "../hooks/useFirestore";
import React, { useEffect, useState } from "react";
import { projectStorage } from "../firebase/config";
import { projectFirestore } from "../firebase/config";

const ImageGrid = ({
  setSelectedImg,
  selectedImg,
  setImageActive,
  isImageActive,
  imageGridToggle,
  setCopyImg,
  copyImg,
  modalStatus,
  imageModal,
}) => {
  const [deleteImg, setDeleteImg] = useState("");
  const [deleteStore, setDeleteStore] = useState("");
  const { docs } = useFirestore("images");
  // console.log("imag")
  useEffect(() => {
    if (deleteImg !== "") {
      console.log(deleteImg);
      var pattern = /[\/\?]/;
      var setdelete = deleteImg;
      setdelete = deleteImg.split(pattern);
      setdelete = setdelete[7];
      setdelete = decodeURI(setdelete);
      console.log(setdelete);
      const deleteRef = projectFirestore
        .collection("images")
        .doc(deleteStore)
        .delete()
        .then(() => {
          console.log(deleteRef);
        })
        .catch(() => {
          console.log("失敗した");
        });
      const deleteImgRef = projectStorage
        .ref(setdelete)
        .delete()
        .then(() => {
          console.log(deleteImgRef);
        })
        .catch(() => {
          console.log("失敗した");
        });
    }
  }, [deleteImg]);
  return (
    <div className={`img-grid ${isImageActive ? "" : "img_upload_move"}`}>
      <div className="overflow">
        {docs &&
          docs.map((doc) => (
            <div className="img-wrap">
              <div
                key={doc.id}
                onClick={() => {
                  if (modalStatus === "quiq") {
                    setCopyImg(doc.url);
                    imageGridToggle();
                  } else if (modalStatus === "nomal") {
                    imageModal(doc.url);
                  }
                }}
              >
                <img src={doc.url} alt="upload pic" />
              </div>
              <div
                className="imagesDelete"
                onClick={(e) => {
                  e.preventDefault();
                  let checkDeleteImageFlag = window.confirm("削除する？");
                  if (checkDeleteImageFlag) {
                    setDeleteImg(doc.url);
                    setDeleteStore(doc.id);
                  }
                }}
              ></div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ImageGrid;
