import acha_prof from "../images/acha_prof.png";
import { useEffect, useState } from "react";
import PortfolioModal from "../comps/PortfolioModal";
import All from "./portfolio/All";
import React from "react"

const Portfolio = () => {
  const [portSelect, setPort] = useState("");
  const [isActive, setActive] = useState("false");
  const [isClick, setClick] = useState(0);
  const [allIndex, setAllIndex] = useState(1);
  const [designIndex, setDesignIndex] = useState(3);
  const [illustIndex, setIllustIndex] = useState(2);
  const [indexUp, setUp] = useState(0)

  const addIndex = (e) => {
    if (e.target.classList.contains("portfolio_type_tag1")) {
    } else {
      setClick(
        isClick + 1);
        let wrapper = document.getElementById("wrapper1");
        wrapper.classList.remove("move")
      e.target.classList.add("click")
      e.target.parentNode.classList.add("move");
    }
  };

  return (
    <div className="portfolio_admin">
      <img
        className={`prof_img ${isActive ? "" : "prof_img_move"}`}
        src={acha_prof}
        alt="prof"
        onClick={() => {
          setActive(!isActive);
        }}
      />
      <All
        type="デザイン"
        portSelect={portSelect}
        setPort={setPort}
        isClick={isClick}
        setClick={setClick}
        index={designIndex}
        setIndex={setDesignIndex}
        addIndex={addIndex}
        indexUp={indexUp}
        setUp={setUp}
      />
      <All
        type="イラスト"
        portSelect={portSelect}
        setPort={setPort}
        isClick={isClick}
        setClick={setClick}
        index={illustIndex}
        setIndex={setIllustIndex}
        addIndex={addIndex}
        indexUp={indexUp}
        setUp={setUp}
      />
      <All
        type="制作物"
        portSelect={portSelect}
        setPort={setPort}
        isClick={isClick}
        setClick={setClick}
        index={allIndex}
        setIndex={setAllIndex}
        addIndex={addIndex}
        indexUp={indexUp}
        setUp={setUp}
      />
      {portSelect && (
        <PortfolioModal portSelect={portSelect} setPort={setPort} />
      )}
    </div>
  );
};
export default Portfolio;
