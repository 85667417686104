// import Admin from "../admin/admin";
import PortfolioGet from "../comps/portfolioGet";
import { projectFirestore } from "../firebase/config";
import React, { useEffect, useState } from "react";

const AdminPortfolio = ({
  deleteId,
  setDeleteId,
  setEdit,
  edit,
  isClick,
  setClick,
  index,
  setIndex,
  addIndex,
  indexUp,
  setUp,
  addEditIndex,
}) => {
  const [isActive, setActive] = useState("false");
  const { portData } = PortfolioGet("portfolio");
  useEffect(() => {
    if (!deleteId == "") {
      const deleteRef = projectFirestore
        .collection("portfolio")
        .doc(deleteId)
        .delete()
        .then(() => {
          console.log("成功した");
        })
        .catch(() => {
          console.log("失敗した");
        });
      console.log(deleteRef);
      console.log(deleteId);
    }
  }, [deleteId]);

  //インデックス
  let tabStyle = {};
  let zIndex = {};
  let opacity = {};

  switch (index) {
    case 1:
      tabStyle = {
        // top: "0px",
      };
      zIndex = {
        zIndex: "3",
        // bottom: "0"
      };
      opacity = {
        // opacity: "1",
      };
      break;
    case 2:
      tabStyle = {
        // top: "160px",
        backgroundColor: "#FFE9B7",
      };
      zIndex = {
        zIndex: "1",
        backgroundColor: "#FFE9B7",
        // bottom: "-160px",
      };
      opacity = {
        // opacity: "1",
      };
      break;
    case 3:
      tabStyle = {
        // top: "320px",
        backgroundColor: "#FFF7E0",
      };
      zIndex = {
        zIndex: "2",
        backgroundColor: "#FFF7E0",
        // bottom: "-320px"
      };
      opacity = {
        // opacity: "1",
      };
      break;
    case 4:
      zIndex = {
        zIndex: "1",
        bottom: "-320px",
      };
      opacity = {
        opacity: "0",
      };
      break;

    default:
      break;
  }

  let wrapper = document.getElementById("wrapper1");
  useEffect(() => {
    if (isClick >= 1) {
      let tagName = document.getElementById("edit_tag");
      if (tagName.classList.contains("click")) {
        tagName.classList.remove("click");
        // wrapper.classList.add("move");
        // console.log(wrapper);
        // console.log(tagName);
        setIndex(4);
        setUp(indexUp + 1);
      }
    }
  }, [isClick]);

  useEffect(() => {
    if (indexUp >= 1) {
      switch (index) {
        case 1:
          setIndex(3);
          break;
        case 2:
          break;
        case 3:
          setIndex(2);
          break;
        case 4:
          setIndex(1);
          break;

        default:
          break;
      }
      setTimeout(function () {
        wrapper.classList.remove("move");
      }, 1000);
    }
  }, [indexUp]);

  return (
    <div
      id={`wrapper${index}`}
      className={`portGet-wrap ${isActive ? "" : "img_upload_move"}`}
      style={zIndex}
    >
      <div
        id="edit_tag"
        className="edit_tag"
        style={tabStyle}
        onClick={addIndex}
      >
        投稿管理
      </div>
      <div className="portfolio-wrap">
        {portData &&
          portData.map((doc) => (
            <div className="portfolio" key={doc.id}>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  let checkDeleteFlg = window.confirm("削除する？");
                  if (checkDeleteFlg) {
                    setDeleteId(doc.id);
                  }
                }}
              ></span>
              <span
                className="edit"
                onClick={() => {
                  setEdit(doc);
                  addEditIndex();
                }}
              ></span>
              <img src={doc.coverImage} alt={doc.title} />
              <p>{doc.title}</p>
              <p>{doc.createDate}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default AdminPortfolio;
