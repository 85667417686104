import { useEffect, useState } from "react";
import { projectFirestore } from "../firebase/config"

const PortfolioGet = (collection) => {
    const [portData, setPortData] = useState([]);

    useEffect(() => {
        const unsub = projectFirestore
            .collection(collection)
            .orderBy("createDate", "desc")
            .onSnapshot(snap => {
                let documents = [];
                snap.forEach( doc => {
                  documents.push({ ...doc.data(), id: doc.id });
                });
                setPortData(documents);
            })
         
            return () => unsub();
    },[collection]);

    return {portData};
}

export default PortfolioGet;