// import PortfolioGet from "../../comps/portfolioGet"
import { projectFirestore } from "../../firebase/config";
import { useEffect, useState } from "react";
// import { AnimatePresence, motion } from "framer-motion";

const All = ({
  setPort,
  portSelect,
  type,
  index,
  setIndex,
  isClick,
  setClick,
  addIndex,
  indexUp,
  setUp,
}) => {
  const PortfolioGet = (collection) => {
    const [portData, setPortData] = useState([]);

    useEffect(() => {
      if (type === "制作物") {
        const unsub = projectFirestore
          .collection(collection)
          .orderBy("createDate", "desc")
          .onSnapshot((snap) => {
            let documents = [];
            snap.forEach((doc) => {
              documents.push({ ...doc.data(), id: doc.id });
            });
            setPortData(documents);
          });

        return () => unsub();
      } else {
      const unsub = projectFirestore
        .collection(collection)
        .orderBy("createDate", "desc")
        .where("type", "==", type)
        .onSnapshot((snap) => {
          let documents = [];
          snap.forEach((doc) => {
            documents.push({ ...doc.data(), id: doc.id });
          });
          setPortData(documents);
        });

      return () => unsub();
      }
    }, [collection]);

    return { portData };
  };

  let tabStyle = {};
  let zIndex = {};
  let opacity = {};

  switch (index) {
    case 1:
      tabStyle = {
        // top: "0px",
      };
      zIndex = {
        zIndex: "3",
        // bottom: "0"
      };
      opacity = {
        // opacity: "1",
      };
      break;
    case 2:
      tabStyle = {
        // top: "160px",
        backgroundColor: "#FFE9B7",
      };
      zIndex = {
        zIndex: "1",
        backgroundColor: "#FFE9B7",
        // bottom: "-160px",
      };
      opacity = {
        // opacity: "1",
      };
      break;
    case 3:
      tabStyle = {
        // top: "320px",
        backgroundColor: "#FFF7E0",
      };
      zIndex = {
        zIndex: "2",
        backgroundColor: "#FFF7E0",
        // bottom: "-320px"
      };
      opacity = {
        // opacity: "1",
      };
      break;
    case 4:
      zIndex = {
        zIndex: "1",
        bottom: "-320px"
      };
      opacity = {
        opacity: "0",
      };
      break;

    default:
      break;
  }

  var typeClass;
  switch (type) {
    case "制作物":
      typeClass = "all";
      break;
    case "デザイン":
      typeClass = "design";
      break;
    case "イラスト":
      typeClass = "illust";
      break;

    default:
      break;
  }
  // let wrapper = document.getElementById("wrapper1");
  useEffect(() => {
    if (isClick >= 1) {
      let tagName = document.getElementById(typeClass);
      if (tagName.classList.contains("click")) {
        tagName.classList.remove("click");
        // wrapper.classList.add("move");
        // console.log(wrapper);
        // console.log(tagName);
        setIndex(4);
        setUp(indexUp + 1);
      }

    }
  }, [isClick]);

  useEffect(() => {
    if (indexUp >= 1) {
      switch (index) {
        case 1:
          setIndex(3);
          break;
        case 2:
          break;
        case 3:
          setIndex(2);
          break;
        case 4:
          setIndex(1);
          break;

        default:
          break;
      }
      // setTimeout(function () {
      //   wrapper.classList.remove("move");
      // }, 1000);
    }
  }, [indexUp]);

  const { portData } = PortfolioGet("portfolio");
  return (
    <div id={`wrapper${index}`} className={`portfolio_all_wrap`} style={zIndex}>
      <p
        id={typeClass}
        className={`portfolio_type_tag${index}`}
        style={tabStyle}
        onClick={addIndex}
      >
        {type}
      </p>

      <div className="all_back" style={tabStyle}>
        <div className="portfolio-wrap" style={opacity}>
          {portData &&
            portData.map((doc) => (
              <div
                className="portfolio"
                key={doc.id}
                onClick={() => {
                  setPort(doc);
                }}
              >
                <img src={doc.coverImage} alt={doc.title} />
                <div className="meta_wrap">
                  <p>{doc.createDate}</p>
                  {doc.tag.split('/').map((tag) => (
                    <p>{tag}</p>
                  ))}
                </div>
                <h2>{doc.title}</h2>
              </div>
            ))}
          <div className="lastMargin"></div>
          <div className="lastMargin"></div>
          <div className="lastMargin"></div>
        </div>
      </div>
    </div>
  );
};
export default All;
