import { BrowserRouter as Router, Route, } from 'react-router-dom'
import PrivateRoute from "./pages/PrivateRouter.js";
import { AuthProvider } from "./contexts/Auth";
import Home from "./pages/Home.js"
import Admin from "./admin/admin"
import SignIn from "./pages/SignIn";
// import SignUp from "./pages/SignUp";
const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div>
          <Route exact path="/" component={Home} />
          <PrivateRoute exact path="/arisa" component={Admin} />
          <Route exact path="/signin" component={SignIn} />
          {/* <Route exact path="/signup" component={SignUp} /> */}
        </div>
      </Router>
    </AuthProvider>
  );
};
export default App;

