import React, { useState, useEffect } from "react";
import ProgressBar from "./ProgressBar";
import ImageGrid from "../comps/ImageGrid";
import Modal from "../comps/Modal";

const UploadForm = ({
  isClick,
  setClick,
  index,
  setIndex,
  addIndex,
  indexUp,
  setUp,
  imageGridToggle,
  setImageActive,
  isImageActive,
  setSelectedImg,
  selectedImg,
  copyImg,
  setCopyImg,
  imageModal
}) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const types = ["image/png", "image/jpeg"];

  const handleChange = (e) => {
    let selected = e.target.files[0];

    if (selected && types.includes(selected.type)) {
      setFile(selected);
      setError("");
    } else {
      setFile(null);
      setError("画像が選択されてないよあーちゃ");
    }
  };

  //インデックス

  let tabStyle = {};
  let zIndex = {};
  let opacity = {};

  switch (index) {
    case 1:
      tabStyle = {
        // top: "0px",
      };
      zIndex = {
        zIndex: "3",
        // bottom: "0"
      };
      opacity = {
        // opacity: "1",
      };
      break;
    case 2:
      tabStyle = {
        // top: "160px",
        backgroundColor: "#FFE9B7",
      };
      zIndex = {
        zIndex: "1",
        backgroundColor: "#FFE9B7",
        // bottom: "-160px",
      };
      opacity = {
        // opacity: "1",
      };
      break;
    case 3:
      tabStyle = {
        // top: "320px",
        backgroundColor: "#FFF7E0",
      };
      zIndex = {
        zIndex: "2",
        backgroundColor: "#FFF7E0",
        // bottom: "-320px"
      };
      opacity = {
        // opacity: "1",
      };
      break;
    case 4:
      zIndex = {
        zIndex: "1",
        bottom: "-320px",
      };
      opacity = {
        opacity: "0",
      };
      break;

    default:
      break;
  }

  let wrapper = document.getElementById("wrapper1");
  useEffect(() => {
    if (isClick >= 1) {
      let tagName = document.getElementById("image_tag");
      if (tagName.classList.contains("click")) {
        tagName.classList.remove("click");
        // wrapper.classList.add("move");
        // console.log(wrapper);
        // console.log(tagName);
        setIndex(4);
        setUp(indexUp + 1);
      }
    }
  }, [isClick]);

  useEffect(() => {
    if (indexUp >= 1) {
      switch (index) {
        case 1:
          setIndex(3);
          break;
        case 2:
          break;
        case 3:
          setIndex(2);
          break;
        case 4:
          setIndex(1);
          break;

        default:
          break;
      }
      setTimeout(function () {
        wrapper.classList.remove("move");
      }, 1000);
    }
  }, [indexUp]);

  

  return (
    <div id={`wrapper${index}`} className={`img_upload`} style={zIndex}>
        <div
          id="image_tag"
          className="image_tag"
          style={tabStyle}
          onClick={addIndex}
        >
          画像追加
        </div>
      <form>
        <label>
          {/* 画像を選択してください */}
          <input type="file" onChange={handleChange} />
          <div className="image_select">
            <div className="image_icon_wrap">
              <svg
                className="image_icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z" />
              </svg>
              <p className="image_icon_text">画像を選択</p>
            </div>
          </div>
        </label>
        <div className="output">
          {error && <div className="error">{error}</div>}
          {file && <div>{file.name}</div>}
          {file && <ProgressBar file={file} setFile={setFile} />}
        </div>
      </form>
      <ImageGrid
      imageModal={imageModal}
        imageGridToggle={imageGridToggle}
        setImageActive={setImageActive}
        isImageActive={isImageActive}
        setSelectedImg={setSelectedImg}
        selectedImg={selectedImg}
        modalStatus={"nomal"}
      />
      
    </div>
  );
};

export default UploadForm;
