import { AnimatePresence, motion } from "framer-motion";
import closeBtn from "../images/compress-alt.svg";

const PortfolioModal = ({ setPort, portSelect }) => {
  const handleClick = (e) => {
    setPort("");
  };

  const backClick = (e) => {
    if (e.target.classList.contains("single_backdrop")) {
      setPort("");
    }
  };

  let tags = portSelect.tag.split('/');

  return (
    <AnimatePresence initial={true}>
      <div className="single_backdrop" onClick={backClick}>
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
          }}
          className="portfolio_single_back"
        >
          <div className="close_wrap" onClick={handleClick}>
            <img src={closeBtn} alt="close_btn" />
            <p>閉じる</p>
          </div>
          <h1 className="single_title">{portSelect.title}</h1>
          <div className="single_meta">
            <p className="single_date">{portSelect.createDate}</p>
            <p className="single_type">{portSelect.type}</p>
            {tags && 
              tags.map ((tag) => (
                <p>{tag}</p>
              ))}
          </div>
          <a href={portSelect.url} target="_blank" rel="noopener noreferrer">{portSelect.url}</a>
          <p className="single_body">{portSelect.content}</p>
          <img
            className="single_img"
            src={portSelect.coverImage}
            alt={portSelect.title}
          />
        </motion.div>
      </div>
    </AnimatePresence>
  );
};

export default PortfolioModal;
