import React, { useEffect, useState } from "react";
import createImage from "../images/RAMEN.svg";
import { timestamp, projectFirestore } from "../firebase/config";

const Create = ({
  copyImg,
  setCopyImg,
  edit,
  setEdit,
  setCreateStatus,
  createStatus,
  setImageActive,
  isImageActive,
  imageGridToggle,
  isClick,
  setClick,
  index,
  setIndex,
  addIndex,
  indexUp,
  setUp,
}) => {
  const [title, setTitle] = useState("");
  const [tag, setTag] = useState("");
  const [createDate, setCreateDate] = useState("");
  const [type, setType] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [content, setContent] = useState("");
  const [url, setUrl] = useState("");
  const [postMessage, setPostMessage] = useState("保存する？");

  const createPost = () => {
    if (createStatus === "記事作成") {
      const date = timestamp();
      projectFirestore
        .collection("portfolio")
        .add({
          title,
          tag,
          createDate,
          type,
          date,
          coverImage,
          content,
          url,
        })
        .then(function () {
          setPostMessage("保存したよあーちゃ！");
          setTitle("");
          setTag("");
          setCreateDate("");
          setType("");
          setCoverImage("");
          setContent("");
          setUrl("");
          setCopyImg("");
        })
        .catch(function () {
          setPostMessage("エラーが起きちゃった！");
        });
    } else if (createStatus === "記事編集中") {
      const date = timestamp();
      projectFirestore
        .collection("portfolio")
        .doc(edit.id)
        .update({
          title,
          tag,
          createDate,
          type,
          url,
          date,
          coverImage,
          content,
        })
        .then(function () {
          setPostMessage("更新したよあーちゃ！");
          setTitle("");
          setTag("");
          setCreateDate("");
          setType("");
          setCoverImage("");
          setContent("");
          setUrl("");
          setCopyImg("");
          setCreateStatus("記事作成");
        })
        .catch(function () {
          setPostMessage("エラーが起きちゃった！");
        });
    }
  };
  useEffect(() => {
    if (!edit == "") {
      console.log("クリエイト変わりました");
      console.log("エディット押されました！！");
      console.log(edit);
      setCreateStatus("記事編集中");
      setTitle(edit.title);
      setTag(edit.tag);
      setCreateDate(edit.createDate);
      setType(edit.type);
      setCoverImage(edit.coverImage);
      setContent(edit.content);
      setUrl(edit.url);
      setCopyImg("");
    }
  }, [edit]);

  useEffect(() => {
    setCoverImage(copyImg);
  }, [copyImg]);

  // const selectStyle = {
  //   display: "none",
  // };


  //インデックス
     let tabStyle = {};
     let zIndex = {};
     let opacity = {};

     switch (index) {
       case 1:
         tabStyle = {
           // top: "0px",
         };
         zIndex = {
           zIndex: "3",
           // bottom: "0"
         };
         opacity = {
           // opacity: "1",
         };
         break;
       case 2:
         tabStyle = {
           // top: "160px",
           backgroundColor: "#FFE9B7",
         };
         zIndex = {
           zIndex: "1",
           backgroundColor: "#FFE9B7",
           // bottom: "-160px",
         };
         opacity = {
           // opacity: "1",
         };
         break;
       case 3:
         tabStyle = {
           // top: "320px",
           backgroundColor: "#FFF7E0",
         };
         zIndex = {
           zIndex: "2",
           backgroundColor: "#FFF7E0",
           // bottom: "-320px"
         };
         opacity = {
           // opacity: "1",
         };
         break;
       case 4:
         zIndex = {
           zIndex: "1",
           bottom: "-320px",
         };
         opacity = {
           opacity: "0",
         };
         break;

       default:
         break;
     }

     let wrapper = document.getElementById("wrapper1");
     useEffect(() => {
       if (isClick >= 1) {
         let tagName = document.getElementById("create_tag");
         if (tagName.classList.contains("click")) {
           tagName.classList.remove("click");
           // wrapper.classList.add("move");
           // console.log(wrapper);
           // console.log(tagName);
           setIndex(4);
           setUp(indexUp + 1);
         }
       }
     }, [isClick]);

     useEffect(() => {
       if (indexUp >= 1) {
         switch (index) {
           case 1:
             setIndex(3);
             break;
           case 2:
             break;
           case 3:
             setIndex(2);
             break;
           case 4:
             setIndex(1);
             break;

           default:
             break;
         }
         setTimeout(function () {
           wrapper.classList.remove("move");
         }, 1000);
       }
     }, [indexUp]);



  return (
    <div id={`wrapper${index}`} className="create-wrap" style={zIndex}>
      <div
        className="create_tag"
        id="create_tag"
        style={tabStyle}
        onClick={addIndex}
      >
        投稿フォーム
      </div>
      <h1>{createStatus}</h1>
      <section>
        <label htmlFor="title-field">タイトル</label>
        <input
          id="title-field"
          type="text"
          value={title}
          onChange={({ target: { value } }) => {
            setTitle(value);
          }}
        />
         <label htmlFor="date-field">制作日 <span>例：2020.01</span></label>
        <input
          id="date-field"
          type="text"
          value={createDate}
          onChange={({ target: { value } }) => {
            setCreateDate(value);
          }}
        />
        <label htmlFor="tag-field">タグ<span>複数つけたい場合は[/]で区切る</span></label>
        <input
          id="tag-field"
          type="text"
          value={tag}
          onChange={({ target: { value } }) => {
            setTag(value);
          }}
        />
       
        {/* <label htmlFor="type-field">タイプ</label> */}
        <div className="radio_field">
          <label htmlFor="type-field-1" className="radio_wrap">
            <input
              id="type-field-1"
              className="type_radio"
              type="radio"
              name="type"
              value="デザイン"
              onChange={({ target: { value } }) => {
                setType(value);
              }}
              checked={type === 'デザイン'}
            />
            デザイン
          </label>
          <label htmlFor="type-field-2" className="radio_wrap">
            <input
              id="type-field-2"
              className="type_radio"
              type="radio"
              name="type"
              value="イラスト"
              onChange={({ target: { value } }) => {
                setType(value);
              }}
              checked={type === 'イラスト'}
            />
            イラスト
          </label>
        </div>
        <label htmlFor="cover-image-field"></label>
        <input
          id="cover-image-field"
          // type="text"
          value={coverImage}
          onChange={({ target: { value } }) => {
            setTag(value);
          }}
          onClick={imageGridToggle}
        />
        <div className="image_select" onClick={imageGridToggle}>
          <div className="image_icon_wrap">
            <svg
              className="image_icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z" />
            </svg>
            <p className="image_icon_text">画像を選択</p>
          </div>
          <img className="sum" src={coverImage} alt={coverImage} />
        </div>
        <label htmlFor="content-field">本文</label>
        <textarea
          id="content"
          type="text"
          value={content}
          onChange={({ target: { value } }) => {
            setContent(value);
          }}
        />
        <label htmlFor="url-field">作品URL</label>
        <input
          id="url-field"
          type="text"
          value={url}
          onChange={({ target: { value } }) => {
            setUrl(value);
          }}
        />
        <div className="create-image-wrap">
          <img
            className="create-image"
            src={createImage}
            alt="送信ボタン"
            onClick={createPost}
          />
          <p className="create-image-text">{postMessage}</p>
        </div>
      </section>
    </div>
  );
};

export default Create;
