import  firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import 'firebase/firestore';

firebase.initializeApp({
  // Authentication infomation
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID
})

const auth = firebase.auth()
const projectStorage = firebase.storage()
const db = firebase.firestore()
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;



export {
  auth, db, projectStorage, projectFirestore,timestamp, firebase as default
}
