import Title from "../images/title.png";
import Portfolio from "../comps/Portfolio";
const Home = () => {
  return (
    <div className="home">
      <Portfolio />
      <img
        className="title_img"
        src={Title}
        alt="やましたありさのポートフォリオ"
      />
    </div>
  );
}
export default Home;
