const Modal = ({ setSelectedImg, selectedImg,  setCopyImg, copyImg }) => {

  const handleClick = (e) => {
    if (e.target.classList.contains('backdrop')) {
      setSelectedImg("");
    }
  }

  // const copyImgClick = () => {
  //   setCopyImg(selectedImg)
  //   setSelectedImg("");
  // }

  

  return (
    <div className="backdrop" onClick={handleClick}>
      <img src={selectedImg} alt="enlarged pic" />
      {/* <span onClick={copyImgClick}>投稿画面に追加する</span> */}
    </div>
  )
}

export default Modal;